import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ConfiguracionRoutingModule } from './configuracion-routing.module';


import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '../utils/utils.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InformacionFormComponent } from './informacion/informacion-form/informacion-form.component';
import { InformacionListComponent } from './informacion/informacion-list/informacion-list.component';
import { GiroListComponent } from './giro/giro-list/giro-list.component';
import { GiroFormComponent } from './giro/giro-form/giro-form.component';
import { ZonaFormComponent } from './zona/zona-form/zona-form.component';
import { ZonaListComponent } from './zona/zona-list/zona-list.component';
import { CreateZonaComponent } from './zona/modal/create-zona/create-zona.component';
import { DeleteZonaComponent } from './zona/modal/delete-zona/delete-zona.component';
import { EditZonaComponent } from './zona/modal/edit-zona/edit-zona.component';
import { ViewZonaComponent } from './zona/modal/view-zona/view-zona.component';
import { ViewGiroComponent } from './giro/modal/view-giro/view-giro.component';
import { EditGiroComponent } from './giro/modal/edit-giro/edit-giro.component';
import { DeleteGiroComponent } from './giro/modal/delete-giro/delete-giro.component';
import { CreateGiroComponent } from './giro/modal/create-giro/create-giro.component';
import { CreateInformacionComponent } from './informacion/modal/create-informacion/create-informacion.component';
import { EditInformacionComponent } from './informacion/modal/edit-informacion/edit-informacion.component';
import { DeleteInformacionComponent } from './informacion/modal/delete-informacion/delete-informacion.component';
import { ViewInformacionComponent } from './informacion/modal/view-informacion/view-informacion.component';
import { VehiculoListComponent } from './vehiculo/vehiculo-list/vehiculo-list.component';
import { VehiculoFormComponent } from './vehiculo/vehiculo-form/vehiculo-form.component';
import { CreateVehiculoComponent } from './vehiculo/modal/create-vehiculo/create-vehiculo.component';
import { EditVehiculoComponent } from './vehiculo/modal/edit-vehiculo/edit-vehiculo.component';
import { DeleteVehiculoComponent } from './vehiculo/modal/delete-vehiculo/delete-vehiculo.component';
import { CategoriaListComponent } from './categoria/categoria-list/categoria-list.component';
import { CategoriaFormComponent } from './categoria/categoria-form/categoria-form.component';
import { CreateCategoriaComponent } from './categoria/modal/create-categoria/create-categoria.component';
import { EditCategoriaComponent } from './categoria/modal/edit-categoria/edit-categoria.component';
import { DeleteCategoriaComponent } from './categoria/modal/delete-categoria/delete-categoria.component';
import { CreateProductoComponent } from './producto/modal/create-producto/create-producto.component';
import { EditProductoComponent } from './producto/modal/edit-producto/edit-producto.component';
import { DeleteProductoComponent } from './producto/modal/delete-producto/delete-producto.component';
import { DeletePedidoComponent } from './pedidos/modal/delete-pedido/delete-pedido.component';
import { EditPedidoComponent } from './pedidos/modal/edit-pedido/edit-pedido.component';
import { CreatePedidoComponent } from './pedidos/modal/create-pedido/create-pedido.component';
import { PedidoListComponent } from './pedidos/pedido-list/pedido-list.component';
import { PedidoFormComponent } from './pedidos/pedido-form/pedido-form.component';
import { ProductoFormComponent } from './producto/producto-form/producto-form.component';
import { ProductoListComponent } from './producto/producto-list/producto-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MonitoreoRealTimeComponent } from './monitoreo/monitoreo-real-time/monitoreo-real-time.component';
import { ReporteEntregasComponent } from './reportes/reporte-entregas/reporte-entregas.component';


@NgModule({
  declarations: [
    InformacionFormComponent,
    InformacionListComponent,
    GiroListComponent,
    GiroFormComponent,
    ZonaFormComponent,
    ZonaListComponent,
    CreateZonaComponent,
    DeleteZonaComponent,
    EditZonaComponent,
    ViewZonaComponent,
    ViewGiroComponent,
    EditGiroComponent,
    DeleteGiroComponent,
    CreateGiroComponent,
    CreateInformacionComponent,
    EditInformacionComponent,
    DeleteInformacionComponent,
    ViewInformacionComponent,
    VehiculoListComponent,
    VehiculoFormComponent,
    CreateVehiculoComponent,
    EditVehiculoComponent,
    DeleteVehiculoComponent,
    CategoriaListComponent,
    CategoriaFormComponent,
    CreateCategoriaComponent,
    EditCategoriaComponent,
    DeleteCategoriaComponent,
    CreateProductoComponent,
    EditProductoComponent,
    DeleteProductoComponent,
    DeletePedidoComponent,
    EditPedidoComponent,
    CreatePedidoComponent,
    PedidoListComponent,
    PedidoFormComponent,
    ProductoFormComponent,
    ProductoListComponent,
    MonitoreoRealTimeComponent,
    ReporteEntregasComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ConfiguracionRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule,

   
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    UtilsModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatPaginatorModule,
    NgbModule,

  ]
})
export class ConfiguracionModule { }
