<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-gray" >
                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Listado de Categorias</span>
                        </div>
                    </div>
                
                        <div class="row mt-4">

                            <div class="col-12 text-right">
                                <mat-form-field class="w-25" appearance="outline">
                                    <mat-label>Buscar por nombre categoria:</mat-label>
                                    <input name="proyecto" autocomplete="off" matInput (keyup)="buscar(txtTermino.value)" #txtTermino >
                                </mat-form-field>
                            </div>

                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="openCreate()" ><i class="fas fa-plus mr-1"> </i> NUEVO</button>&nbsp;
                                <button  class="btn btn-success" (click)="export()"><i class="fas fa-file-excel mr-2"> </i>EXPORTAR</button>&nbsp;
                            </div>
                        </div>
                </div>



                <div class="card-body">




                    <div class="table-responsive">
                        <br>
                        <table class="table table-bordered">
                            <thead style="background-color: #ff6c04; color: #fff; font-weight: lighter;">
                                <tr>
                                    <th>Fecha de creación</th>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Estado</th>
                                    <th class="text-nowrap text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of categoria">

                                    <td>{{data.created | date: 'dd/MM/yyyy   HH:mm:ss'}}</td>
                                    <td>{{data.nombre}}</td>
                                    <td>{{data.descripcion}}</td>
                                    <td *ngIf="data.estado == 0">Inactivo</td>
                                    <td *ngIf="data.estado == 1">Activo</td>
                                    <td *ngIf="data.estado == null">Inactivo</td>

                                    <td class="text-nowrap text-center">
                                        <button  class="cursor btn btn-warning mr-2" (click)="openEdit(data)" > <i class="fa fa-edit"> </i>  </button> 
                                        <button  class="cursor btn btn-danger mr-2" (click)="openDelete(data)"   > <i class="fas fa-trash-alt"> </i>  </button> 
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                <button  class="btn btn-secondary" (click)="cambiarPagina(-5)"> <i class="fas fa-arrow-left mr-1"> </i> Anteriores</button>
                    &nbsp;
                    <button  class="btn btn-success" (click)="cambiarPagina(5)">Siguientes  <i class="fas fa-arrow-right mr-1"> </i> </button> 

                </div>



            </div>
        </div>
    </div>
</div>