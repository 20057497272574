import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/guard/auth.guard';
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { GiroListComponent } from './giro/giro-list/giro-list.component';
import { ZonaListComponent } from './zona/zona-list/zona-list.component';
import { InformacionListComponent } from './informacion/informacion-list/informacion-list.component';
import { VehiculoListComponent } from './vehiculo/vehiculo-list/vehiculo-list.component';
import { PedidoListComponent } from './pedidos/pedido-list/pedido-list.component';
import { ProductoListComponent } from './producto/producto-list/producto-list.component';
import { CategoriaListComponent } from './categoria/categoria-list/categoria-list.component';
import { PedidoFormComponent } from './pedidos/pedido-form/pedido-form.component';
import { MonitoreoRealTimeComponent } from './monitoreo/monitoreo-real-time/monitoreo-real-time.component';
import { ReporteEntregasComponent } from './reportes/reporte-entregas/reporte-entregas.component';


const routes: Routes = [
  {
    path: 'admin', canActivate: [AuthGuard], children: [
      { path: 'giro.list', component: GiroListComponent },
      { path: 'zona.list', component: ZonaListComponent },
      { path: 'informacion.list', component: InformacionListComponent },
      { path: 'vehiculos.list', component: VehiculoListComponent },
      { path: 'categorias.list', component: CategoriaListComponent },
      { path: 'productos.list', component: ProductoListComponent },
      { path: 'pedidos.list', component: PedidoListComponent },
      { path: 'pedidos.form', component: PedidoFormComponent },
      { path: 'monitoreo', component: MonitoreoRealTimeComponent },
      { path: 'reporte.entregas', component: ReporteEntregasComponent },
      { path: '', component: HeaderComponent, outlet: 'header' },
      { path: '', component: SidebarComponent, outlet: 'sidebar' }
    ]
  },
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})

export class ConfiguracionRoutingModule { }
